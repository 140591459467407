import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Merci !" />
    
    <div className="container">
      <h1>Merci pour votre prise de contact</h1>
      <p>Notre équipe va étudier votre demande, nous vous revenons dans les meilleurs délais.</p>
    </div>
  </Layout>
)

export default NotFoundPage
